<template>
    <v-dialog persistent max-width="764px" v-model="dialog">
        <v-card>
            <v-card-title>Create Project Payroll</v-card-title>
            <v-card-text class="">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="d-flex justify-center"><v-checkbox class="mt-2" v-model="isAllSelected" @change="selectItems"></v-checkbox></th>
                                    <th class="text-center">Period</th>
                                    <th class="text-center">Project Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(item, index) in projects">
                                    <td class="d-flex justify-center">
                                        <v-checkbox hide-details class="mt-2" v-model="item.selected" @change="selectItem(item)"></v-checkbox>
                                    </td>
                                    <td class="text-center" v-text="item.period.text"></td>
                                    <td class="text-center" v-text="item.project.name"></td>
                                </tr>
                                <tr><td colspan="3" v-if="projects.length === 0">No project is found</td></tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="text-right pr-6 pl-6">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

import { stringifyJSON } from '@/utils/encodes'
export default {
    name: 'PayrollProjectCreateDialog',
    props: {
        projects: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            dialog: false,
            loading: false,
            isAllSelected: false,
            selectedItems: [],
            selectedItemIds: [],
            selectedObject: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.projects.forEach((item) => item.selected = false )
            this.isAllSelected = false
            this.selectedItems = []
            this.selectedItemIds = []
            this.selectedObject = {}
        },
        create: function() {
            this.loading = true
            const object = { period: stringifyJSON(this.selectedObject), items: stringifyJSON(this.selectedItems) }
            this.$store.dispatch('epanel/payroll/createPayrollProjects', object).then((response) => {
                this.close()
                this.$emit('created', { message: response.message })
            }).catch(() => {
                this.loading = false
            })
        },
        selectItem: function(item){
            const value = item.project.value
            var itemIndex = this.selectedItemIds.indexOf(value)
            if(itemIndex > -1){
                this.selectedItems.splice(itemIndex, 1)
                this.selectedItemIds.splice(itemIndex, 1)
            }else{
                this.selectedItems.push(value)
                this.selectedItemIds.push(value)
            }

            if(this.selectedItemIds.length === 1){
                this.selectedObject = { start_date: item.period.start_date, end_date: item.period.end_date }
            }else if(this.selectedItemIds.length === 0){
                this.selectedObject = {}
            }

            this.isAllSelected = this.selectedItemIds.length === this.projects.length
        },
        selectItems: function(isSelected){
            this.selectedItems = []
            this.selectedItemIds = []
            this.selectedObject = {}

            if(isSelected){
                this.projects.forEach((item) => {
                    item.selected = true
                    this.selectedItems.push(item.project.value)
                    this.selectedItemIds.push(item.project.value)
                })
                this.selectedObject = { start_date: this.projects[0].period.start_date, end_date: this.projects[0].period.end_date }
            }else{
                this.projects.forEach((item) => item.selected = false )
            }
        },

    }
}

</script>