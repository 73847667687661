<template>
	<div>
         <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Project Payroll Management</p>
                <p class="text-title mb-0">You do not have permission to view all project payrolls</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0 mb-3">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-select dense clearable hide-details label="Year" :items="years" :menu-props="{top: false, offsetY: true}" v-model="filters.year"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" v-if="projects.length > 0">
                                <v-autocomplete dense clearable hide-details label="Project" :items="projects" v-model="filters.project"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-select dense clearable hide-details label="Status" :items="statuses" :menu-props="{top: false, offsetY: true}" v-model="filters.status"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_add && emptyProjects.length > 0">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='openCreateDialog'>
                        <v-icon small left>mdi-plus</v-icon> Create Project Payroll
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-expansion-panels multiple v-model="panel">
                        <v-expansion-panel :key="index" v-for="(item, index) in items">
                            <v-expansion-panel-header>
                                <span v-text="item.category"></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div v-if="item.payrolls.length !== 0 && checkCanDownload(item) === true">
                                    <div v-if="checkQuantity(item.payrolls) !== 0">
                                        <v-btn class="cpf-button" small color="primary" @click="openDownloadDialog(item)">Download CPF Payroll</v-btn>
                                    </div>  
                                </div>
                                <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :items-per-page="100" :headers="headers" :items="item.payrolls">
                                    <template v-slot:item='{ item }'>
                                        <tr>
                                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.project"></td>
                                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start_date"></td>
                                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end_date"></td>
                                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.quantity"></td>
                                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.status"></td>
                                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                            <v-menu bottom offset-y v-if="hasMenu(item)">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small icon v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list dense>
                                                    <v-list-item :to="getViewUrl(item.id)" target="_blank" v-if="item.can_view">
                                                        <v-list-item-title>View Payroll</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </template>
		<create-dialog ref="createDialog" :projects="emptyProjects" @created="updateItems"></create-dialog>
		<download-dialog ref="downloadDialog" @downloaded="handleDownloaded"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
        <message-notifier ref="notifier"></message-notifier>
    </div>
</template>


<script>

import { makeYearList } from '@/utils/dates'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import PayrollProjectCreateDialog from '../project/PayrollProjectCreateDialog.vue'
import CpfPayrollDownloadDialog from './CpfPayrollDownloadDialog.vue';
import SimpleActionDialog from '../../../shared/SimpleActionDialog.vue';

export default {
	name: 'PayrollProjectList',
	components: {
        messageNotifier: MessageNotifier,
        createDialog: PayrollProjectCreateDialog,
        downloadDialog: CpfPayrollDownloadDialog,
        simpleActionDialog: SimpleActionDialog,
	},
	data () {
		return {
            panel: [],
			loading: false,
			selectedIndex: -1,
			filters: {
				year: '',
				project: '',
				status: '',
				page: 1
			},
			permissions: {},
			statuses: [],
			projects: [],
			emptyProjects: [],
			items: [],
			headers: [],
			pagination: {},
            can_download: false,
		}
	},
	computed: {
        createDialog() {
            return this.$refs.createDialog
        },
        notifier() {
            return this.$refs.notifier
        },
        years() {
            return makeYearList(2021)
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
            // && checkCanDownload(item) === true
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/payroll/getPayrollProjects', this.filters).then((response) => {
				this.statuses = response.data.statuses
				this.projects = response.data.projects
				this.emptyProjects = response.data.empty_projects
				this.items = response.data.items
				this.headers = response.data.headers
				this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { year: '', project: '', status: '', page: 1}
		},
        updateItems: function(item) {
            this.reset()
            this.get()
            this.openNotifier(item.message)
        },
		openCreateDialog: function() {
            if(this.permissions.can_add){
                this.createDialog.open()
            }
		},
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        getViewUrl: function(id){
            return '/epanel/payroll/project/' + id + '/info'
        },
        hasMenu: function(item) {
            return item.can_view
        },
        checkCanDownload: function(items) {
            let payrolls = items.payrolls
            let status_checker = []

            payrolls.map(project => {
                if(project.status === "Draft") {
                    status_checker.push(false)
                } else if(project.status === "Published"){
                    status_checker.push(true)
                }
            })
            
            return this.trueChecker(status_checker)
        },
        trueChecker: function(arr) {
            return arr.every(element => element === true);
        },
        openDownloadDialog: function(item) {
            let payroll = item.payrolls[0];
            let payroll_id = payroll.id;
            const object = { payroll: payroll_id }
            this.downloadDialog.updateObject(object)
            this.downloadDialog.open()
        },
        handleDownloaded: function(){
            this.loadingDialog.open()
        },
        checkQuantity: function(payrolls) {
            let empty_array = []
            for (let i = 0; i < payrolls.length; i ++) {
                empty_array.push(payrolls[i].quantity)
            }

            let sum = empty_array.reduce((a, b) => a + b, 0)
            return sum
        },
	}
}

</script>

<style>
    .cpf-button {
        margin-bottom: 10px
    }
</style>